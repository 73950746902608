import React, { useState, ChangeEvent, KeyboardEvent } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

type SearchBarProps = {
  onSearch: (query: string) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(query);
    }
  };

  const handleSearchButton = () => {
    onSearch(query);
  };

  return (
    <div className="w-4/5">
      <div className="flex items-center border bg-purple-50 border-purple-500 py-3 rounded px-3">
        <input
          className="px-3 appearance-none bg-transparent border-none w-full text-gray-700 leading-tight focus:outline-none"
          type="text"
          placeholder="Search..."
          value={query}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <button
          className="flex-shrink-0 bg-purple-500 hover:bg-purple-700 border-purple-500 hover:border-purple-700 text-sm text-white py-1 px-1 rounded"
          type="button"
          onClick={handleSearchButton}
        >
          <MagnifyingGlassIcon className="h-5 w-5 text-white" />
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
