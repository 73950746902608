// src/components/AdminConsoleDefaultProfile.js

import React, { useState, useEffect } from 'react';
import { Supabase, supabaseConfigurationTableName } from '../variables/Supabase';

const configurationTable = Supabase.from(supabaseConfigurationTableName);

interface Profile {
    id: number;
    profile_name: string;
    profile_default: boolean;
  }

  const AdminConsoleDefaultProfile = () => {
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [selectedDefaultProfile, setSelectedDefaultProfile] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchProfiles();
    }, []);

    const updateDefaultProfile = async (id: number | string, profile_name: string) => {
        // Use configurationTable.update, not supabaseConfigurationTableName.update
        await configurationTable.update({ profile_default: true }).eq('id', id);
        setSelectedDefaultProfile(profile_name);
      }

    const fetchProfiles = async () => {
        const { data, error } = await configurationTable
            .select('id, profile_name:profile_json->profile_name, profile_default')
            .order('id', { ascending: true });

        if (error) {
            console.error(`Error: ${error.message}`);
        } else if (data) {
            setProfiles(data);
            const defaultProfile = data.find(profile => profile.profile_default);

            if (data.length === 1) {
                await updateDefaultProfile(data[0].id, data[0].profile_name);
            } else if (defaultProfile) {
                setSelectedDefaultProfile(defaultProfile.profile_name);
            }

            setLoading(false);
        }
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedDefaultProfile(e.target.value);
        setShowConfirmation(true);
    };

    const handleSetDefaultProfile = async () => {
        setShowConfirmation(false);

        const profile = profiles.find(profile => profile.profile_name === selectedDefaultProfile);
        if (profile) {
            await updateDefaultProfile(profile.id, profile.profile_name);
        }

        fetchProfiles();
    };

    const handleCancelSetDefaultProfile = () => {
        setShowConfirmation(false);
    };

    return (
        <div className="flex items-center">
            <h2 className="mr-4 font-bold">Default Configuration</h2>
            {loading ? (
                <div className="animate-pulse">
                    <div className="h-10 bg-gray-300 rounded w-48 mb-2"></div>
                </div>
            ) : (
                <select
                    value={selectedDefaultProfile}
                    onChange={handleSelectChange}
                    className="mt-1 p-2 border border-gray-300 rounded text-gray-700 focus:outline-none focus:border-purple-300"
                >
                    {profiles.map((profile, index) => (
                        <option key={index} value={profile.profile_name}>
                            {profile.profile_name}
                        </option>
                    ))}
                </select>
            )}

            {showConfirmation && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-md">
                        <h2 className="text-lg font-bold mb-4">Confirm Set Default Configuration</h2>
                        <p className="mb-4">Are you sure you want to set {selectedDefaultProfile} as the default configuration?</p>
                        <div className="flex justify-center">
                            <button
                                type="button"
                                onClick={handleCancelSetDefaultProfile}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={handleSetDefaultProfile}
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminConsoleDefaultProfile;
