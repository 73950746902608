import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

interface PaginationProps {
  data: any[];  // Specify the type of data here
  page: number;
  setPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ data, page, setPage }) => {
  const limit = 10;
  const isLastPage = data.length < limit;

  const nextPage = () => {
    if (!isLastPage) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  return (
    <div className="mt-4 flex flex-col items-center">
      <div className="flex items-center">
        <button
          className={`bg-purple-500 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded flex items-center justify-center text-center w-1/2 mr-2 ${page === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={prevPage}
          disabled={page === 0}
        >
          <ChevronLeftIcon className="mr-1 h-5 w-5" />
        </button>
        <span className="w-60 text-center">{`Page ${page + 1}`}</span>
        <button
          className={`bg-purple-500 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded flex items-center justify-center text-center w-1/2 ml-2 ${isLastPage ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={nextPage}
          disabled={isLastPage}
        >
          <ChevronRightIcon className="ml-1 h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
