import { fastApi, fastApiKey } from "../variables/FastAPI";

type RuleType = {
  rule_base64: string;
  // add other properties if exist
};

type SelectedConfigurationType = {
  pipeline: string;
  backend: string;
  converter: string;
  target: string;
  // add other properties if exist
};

const translateRule = async (rule: RuleType, selectedConfiguration: SelectedConfigurationType) => {
  if (!selectedConfiguration) {
    throw new Error('No selected configuration');
  }

  const requestBody = {
    rule: rule.rule_base64,
    pipeline: selectedConfiguration.pipeline,
    backend: selectedConfiguration.backend,
    converter: selectedConfiguration.converter,
    target: selectedConfiguration.target,
  };

  if (!fastApi) {
    throw new Error('FastAPI URL is undefined');
  }

  try {
    const response = await fetch(fastApi, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${fastApiKey}`,
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(await response.text());
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    return responseData.processed_data;
  } catch (error) {
    console.error('There was a problem with the POST request:', error);
    return null;
  }
};

export default translateRule;
