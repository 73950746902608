import { Supabase, supabaseConfigurationTableName } from '../variables/Supabase';

export const FetchProfilesList = async () => {
  try {
    const { data: configurations, error } = await Supabase
      .from(supabaseConfigurationTableName)
      .select('id, profile_name:profile_json->profile_name, backend:profile_json->backend, pipeline:profile_json->pipeline, url:profile_json->url, target:profile_json->target, converter:profile_json->converter, profile_default')
      .order('id', { ascending: true });

    if (error) {
      console.error(`Error: ${error.message}`);
      throw new Error('Error retrieving profiles from Supabase');
    }

    const selectedConfiguration = configurations.find(item => item.profile_default);
    return {configurations, selectedConfiguration};

  } catch (error) {
    console.error('Error retrieving profiles from Supabase:', error);
    return null;
  }
};
