//src/components/TableComponent.js
import React, { useCallback, memo } from 'react';
import TranslateRule from './TranslateRule';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

const setTranslation = (text: string): string => encodeURIComponent(text);
const getAttackTag = (tags: string[]): string => tags?.find((tag: string) => /^attack\./.test(tag)) || '';
const getTechniqueTag = (tags: string[]): string => tags?.find((tag: string) => /^attack\.t\d{4}(\.\d{3})?$/i.test(tag)) || '';
const toSentenceCase = (str: string): string => str?.charAt(0).toUpperCase() + str.slice(1).toLowerCase() || '';

function getColors(level: string): string {
  switch (level.toLowerCase()) {
    case 'critical':
      return 'bg-red-700 text-white';
    case 'high':
      return 'bg-red-500 text-white';
    case 'medium':
      return 'bg-yellow-500 text-white';
    case 'low':
      return 'bg-blue-500 text-white';
    case 'informational':
      return 'bg-gray-300 text-gray-800';
    default:
      return '';
  }
}

const TableHeader = memo(() => (
  <thead className="bg-purple-500 text-white">
    <tr>
      <th className="px-4 py-2 text-left">Rule Name</th>
      <th className="px-4 py-2 text-left">Source</th>
      <th className="px-4 py-2 text-center">Mitre Tactic</th>
      <th className="px-4 py-2 text-center">Mitre Technique</th>
      <th className="px-4 py-2 text-center">Level</th>
      <th className="px-4 py-2 text-center">Search</th>
    </tr>
  </thead>
));

interface Rule {
  id: number,
  title: string,
  rule_repository: string,
  tags: string[],
  level: string,
  rule_base64: string // This is the new line
}

interface Configuration {
  url: string,
  pipeline: string,
  backend: string,
  converter: string,
  target: string,
}

interface RuleTableProps {
  data: Rule[],
  onRowClick: (rule: Rule) => void,
  selectedConfiguration: Configuration
}

const RuleTable: React.FC<RuleTableProps> = ({ data, onRowClick, selectedConfiguration }) => {
  const rowClickHandler = useCallback((rule: Rule) => onRowClick(rule), [onRowClick]);

  const handleSearchClick = async (e: React.MouseEvent, rule: Rule) => {
    e.stopPropagation();

    const translatedRule = await TranslateRule(rule, selectedConfiguration);

    if (translatedRule) {
      const searchQuery = translatedRule;
      const encodedSearchQuery = setTranslation(searchQuery);
      const apiUrl = selectedConfiguration.url;
      const url = `${apiUrl}/en-GB/app/search/search?q=search%20${encodedSearchQuery}`;
      window.open(url, '_blank');
    }
  };

  return (
    <table className="w-4/5 mx-auto border border-gray-300 rounded-md shadow-md bg-white">
      <TableHeader />
      <tbody>
        {data.map((rule) => (
          <tr key={rule.id} onClick={() => rowClickHandler(rule)} className="bg-purple-50 hover:bg-purple-100 cursor-pointer">
            <td className="px-4 py-2 border border-gray-300 text-gray-600 font-medium">{rule.title}</td>
            <td className="px-4 py-2 border border-gray-300 text-gray-600 text-center">{rule.rule_repository}</td>
            <td className="px-4 py-2 border border-gray-300 text-gray-600 text-center">{getAttackTag(rule.tags)}</td>
            <td className="px-4 py-2 border border-gray-300 text-gray-600 text-center">{getTechniqueTag(rule.tags)}</td>
            <td className="px-4 py-2 border border-gray-300 text-gray-600 text-center">
              <span className={`px-3 py-1 inline-flex items-center rounded ${getColors(rule.level)}`}>
                {toSentenceCase(rule.level)}
              </span>
            </td>
            <td className="px-4 py-2 border border-gray-300 text-gray-600">
              <div className="flex justify-center items-center">
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded flex items-center justify-center"
                  onClick={(e) => handleSearchClick(e, rule)}
                >
                  <MagnifyingGlassIcon className="h-6 w-20" />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RuleTable;

