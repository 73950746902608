import { MouseEvent, FC, useState, useEffect } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Square2StackIcon, XMarkIcon, CheckIcon } from '@heroicons/react/24/solid';

type CloseButtonProps = {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void,
}

type SelectedRowData = {
  rule_json: {
    title: string,
  },
  rule_base64: string,
}

type RulePopupProps = {
  selectedRowData: SelectedRowData,
  translation: string,
  onTranslate: () => void,
  onClose: (event: MouseEvent<HTMLButtonElement>) => void,
}

const CloseButton: FC<CloseButtonProps> = ({ onClick }) => (
  <button className="text-white font-bold py-2 px-4" onClick={onClick}>
    <XMarkIcon className="h-5 w-5" />
  </button>
);

// Main Component
const RulePopup: FC<RulePopupProps> = ({ selectedRowData, translation, onTranslate, onClose }) => {
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    onTranslate(); // Call onTranslate when the component is mounted
  }, []); // Empty dependency array to ensure it only runs once

  const handleCopyClick = () => {
    navigator.clipboard.writeText(translation);
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 2000);
  };

  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center">
      <div className="bg-white rounded-md w-[90vw] h-[80vh] flex flex-col relative">
        <div className="bg-purple-500 rounded text-white text-center py-2 flex justify-between items-center">
          <h2 className="text-xl font-bold px-4">{selectedRowData.rule_json.title}</h2>
          <CloseButton onClick={onClose} />
        </div>
        <div className="popup-grid grid grid-cols-2 gap-8 h-full overflow-auto py-2 px-4">
          <div className="flex flex-col h-full overflow-auto">
            <SyntaxHighlighter language="yaml" style={materialLight} className="text-sm" lineProps={{ style: { wordBreak: 'break-word', whiteSpace: 'pre-wrap' } }} wrapLines>
              {window.atob(selectedRowData.rule_base64)}
            </SyntaxHighlighter>
          </div>
          <div className="flex flex-col h-full overflow-auto relative">
            <SyntaxHighlighter style={materialLight} className="text-sm overflow-auto" lineProps={{ style: { wordBreak: 'break-word', whiteSpace: 'pre-wrap' } }} wrapLines>
              {translation || "Translating... Please wait"}
            </SyntaxHighlighter>
            {translation && (
              <button className="absolute top-0 right-0 py-6 px-4" onClick={handleCopyClick}>
                {isClicked
                  ? <CheckIcon className="h-6 w-6 text-gray-500 opacity-80 hover:opacity-100 transition duration-500 ease-in-out" />
                  : <Square2StackIcon className="h-6 w-6 text-gray-500 opacity-80 hover:opacity-100 transition duration-500 ease-in-out" />
                }
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RulePopup;
