// src/components/Auth.js
import { useContext, useState, useEffect, createContext, ReactNode } from 'react';
import { Supabase } from '../variables/Supabase';
import { Session, User } from '@supabase/supabase-js'

// create a context for authentication
const AuthContext = createContext<{ 
    session: Session | null, 
    user: User | undefined, 
    userId: string | undefined, 
    signOut: () => void 
}>({ session: null, user: undefined, userId: undefined, signOut: () => {} });

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider = (props: AuthProviderProps) => {
    const [session, setSession] = useState<Session | null>(null);
    const [user, setUser] = useState<User | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const setData = async () => {
            const { data: { session }, error } = await Supabase.auth.getSession();
            if (error) throw error;
            setSession(session);
            setUser(session?.user);
            setLoading(false);
        };

        const { data: listener } = Supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
            setUser(session?.user);
            setLoading(false);
        });

        setData();

        return () => {
            listener?.subscription.unsubscribe();
        };
    }, []);

    const value = {
        session,
        user,
        userId: user?.id,  // Added this line
        signOut: () => Supabase.auth.signOut(),
    };

    // use a provider to pass down the value
    return (
        <AuthContext.Provider value={value}>
            {!loading && props.children}
        </AuthContext.Provider>
    );
};

// export the useAuth hook
export const useAuth = () => {
    return useContext(AuthContext);
};
