//src/App.js
import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AdminConsole from './components/AdminConsole';
import Footer from './components/Footer';
import LandingPage from './components/LandingPage';
import Notification from './components/Notification';
import Pagination from './components/Pagination';
import RulePopup from './components/RulePopup';
import RuleTable from './components/RuleTable';
import TopNavbar from './components/TopNavbar';
import TranslateRule from './components/TranslateRule';
import { AuthProvider } from './components/Auth';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import SearchBar from './components/SearchBar';
import { AppDataQueries } from './components/AppDataQueries';
import ProfileOptions from './components/ProfileOptions';

const App = () => {
  // State variables
  const [page, setPage] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [translation, setTranslation] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [selectedConfiguration, setSelectedConfiguration] = useState(null);


  // Event handlers
  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    togglePopup();
  };

  const handleTranslate = async () => {
    const translatedRule = await TranslateRule(selectedRowData, selectedConfiguration);
  
    if (translatedRule) {
      setTranslation(translatedRule);
    } else {
      setTranslation('Error: Translation failed.');
    }
  };
  

  const clearTranslationAndClose = () => {
    setTranslation('');
    togglePopup();
  };

  // Toggles
  const togglePopup = () => setPopupOpen(!popupOpen);

  const [searchQuery, setSearchQuery] = useState(null);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  // Render
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppDataQueries page={page} searchQuery={searchQuery} handleSearch={handleSearch}>
          {(data) => (
            <div className="flex flex-col h-screen">
              <TopNavbar className="h-12" /> {/* Assuming navbar is 12 units tall */}
              <div className="flex-grow overflow-auto">
                <Routes>
                  {/* <Route path="/" element={<LandingPage />} /> */}
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/"
                    element={
                      <ProtectedRoute>
                        <div className="h-full w-full relative bg-gray-100 flex flex-col justify-center items-center">
                          <div className="w-4/5 flex py-5">
                            <SearchBar onSearch={handleSearch} />
                            <ProfileOptions selectedConfiguration={selectedConfiguration} setSelectedConfiguration={setSelectedConfiguration} />
                          </div>
                          <RuleTable data={data} onRowClick={handleRowClick} selectedConfiguration={selectedConfiguration} />
                          <Pagination page={page} setPage={setPage} data={data} />
                          {popupOpen && (
                            <RulePopup
                              selectedRowData={selectedRowData}
                              translation={translation}
                              onTranslate={handleTranslate}
                              onClose={clearTranslationAndClose}
                              setShowNotification={setShowNotification}
                            />
                          )}
                          <Notification show={showNotification} />
                        </div>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/config"
                    element={
                      <ProtectedRoute>
                        <AdminConsole />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </div>
              <Footer className="h-12" /> {/* Assuming footer is 12 units tall */}
            </div>
          )}
        </AppDataQueries>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;