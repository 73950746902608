import { Supabase, supabaseConfigurationTableName } from '../variables/Supabase';

const configTable = Supabase.from(supabaseConfigurationTableName);

export const fetchConfiguration = async () => {
    return await configTable
        .select('id, profile_name:profile_json->profile_name, profile_json, profile_default, user_id')
        .order('id', { ascending: true });
};

export const postConfiguration = async (
    userId: string, 
    id: number, 
    profile_name: string, 
    url: string, 
    backend: string, 
    pipeline: string, 
    isNewProfile: boolean, 
    converter: string, 
    target: string
) => {
    const profileJson = {
        "user_id": userId,
        "profile_json": {
            "backend": backend,
            "converter": converter,
            "pipeline": pipeline,
            "profile_name": profile_name,
            "url": url,
            "target": target,
        },
    };

    if (isNewProfile) {
        return await configTable.insert([profileJson]);
    } else {
        return await configTable.update([profileJson]).eq('id', id);
    }
};

export const deleteConfiguration = async (userId: string, id: number) => {
    return await configTable.delete().eq('id', id).eq('user_id', userId);
};

// I don't believe this code is required anymore as fetching the profile is being undertaken by other means, for now...

// export const getApiUrl = async () => {
//     const { data, error } = await Supabase
//         .from(configurationTableName)
//         .select('backend:profile_json->backend,pipeline:profile_json->pipeline,url:profile_json->url,profile_default, user_id') // added user_id
//         .eq('profile_default', true)
//         .limit(1)
//         .single();
//     if (error) {
//         console.error(error);
//         return null;
//     } else {
//         return data.url;
//     }
// };