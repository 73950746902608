import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Cog8ToothIcon,
  EyeIcon,
  RocketLaunchIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/solid';
import { useAuth } from './Auth';

const TopNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, signOut } = useAuth();

  const isAuthenticated = !!user;

  const handleLogout = async () => {
    await signOut();
  };

  const getTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Threat Detection Library';
      case '/config':
        return 'Configuration Console';
      default:
        return '';
    }
  };

  return (
    <header className="bg-purple-600 p-5 shadow-md">
      <nav className="container mx-auto flex items-center justify-between text-white font-bold">
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <EyeIcon className="h-6 w-6 mr-1" />
            <p className="text-xl">Sigilite</p>
          </Link>
        </div>
        <div className="flex-grow text-center">
          <h1 className="text-xl font-bold">{getTitle()}</h1>
        </div>
        {isAuthenticated && (
          <>
            <Link to="/" className="flex-none items-center">
              <div className="flex items-center mr-3">
                <RocketLaunchIcon className="h-6 w-6 mr-1" />
                <p>Library</p>
              </div>
            </Link>
            <Link to="/config" className="flex-none items-center">
              <div className="flex items-center mr-3">
                <Cog8ToothIcon className="h-6 w-6 mr-1" />
                <p>Configuration</p>
              </div>
            </Link>
          </>
        )}
        <div className="flex-none items-center">
          <button
            className="flex items-center"
            onClick={isAuthenticated ? handleLogout : () => navigate('/login')}
          >
            <ArrowRightOnRectangleIcon className="h-6 w-6 mr-1" />
            <p>{isAuthenticated ? 'Logout' : 'Login'}</p>
          </button>
        </div>
      </nav>
    </header>
  );
};

export default TopNavbar;
