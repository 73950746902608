// AppDataQueries.js
import { useState, useEffect } from 'react';
import { Supabase, supabaseSigmaTableName } from '../variables/Supabase';
import { ReactNode } from 'react';

const sigmaTableName = supabaseSigmaTableName;

interface AppDataQueriesProps {
  page: number,
  searchQuery: string,
  handleSearch: (query: string) => void,
  children: (data: any, handleSearch: (query: string) => void) => ReactNode
}

export const AppDataQueries = ({ page, searchQuery, handleSearch, children }: AppDataQueriesProps) => {
  const limit = 10;
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchRules = async () => {
      let query;

      if (searchQuery) {
        query = Supabase
          .from(sigmaTableName)
          .select('*,level:rule_json->level,title:rule_json->title,tags:rule_json->tags')
          .order('id', { ascending: true })
          .ilike('rule_text', `%${searchQuery}%`)
          .range(page * limit, (page + 1) * limit - 1);
      } else {
        query = Supabase
          .from(sigmaTableName)
          .select('*,level:rule_json->level,title:rule_json->title,tags:rule_json->tags')
          .order('id', { ascending: true })
          .range(page * limit, (page + 1) * limit - 1);
      }

      const { data: rules, error } = await query;

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        setData(rules);
      }
    };

    fetchRules();
  }, [page, searchQuery]);

  return children(data, handleSearch);
};
