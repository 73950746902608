import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

interface NotificationProps {
  show: boolean;
}

const Notification: React.FC<NotificationProps> = ({ show }) => {
  if (!show) return null;

  return (
    <div className="fixed bottom-0 right-0 m-4 bg-green-500 text-white font-bold py-2 px-4 rounded flex items-center">
      <CheckCircleIcon className="mr-2" />
      Copied!
    </div>
  );
};

export default Notification;
