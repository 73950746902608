import React, { useState, useEffect, ChangeEvent } from 'react';
import { FetchProfilesList } from './FetchProfile';

interface Configuration {
  id: number;
  profile_name: string;
  // add more fields depending on the structure of your configuration objects
}

interface ProfileOptionsProps {
  selectedConfiguration: Configuration | null;
  setSelectedConfiguration: (config: Configuration | null) => void;
}

const ProfileOptions: React.FC<ProfileOptionsProps> = ({ selectedConfiguration, setSelectedConfiguration }) => {
  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfiles = async () => {
      const { configurations, selectedConfiguration } = await FetchProfilesList();

      if (configurations) {
        setConfigurations(configurations);
        setSelectedConfiguration(selectedConfiguration);
        setLoading(false);
      }
    };

    fetchProfiles();
  }, []);

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedProfileName = e.target.value;
    const selectedConfig = configurations.find(config => config.profile_name === selectedProfileName);

    setSelectedConfiguration(selectedConfig || null);
  };

  return (
    <div className="w-1/5 ml-5">
      {loading ? (
        <div className="animate-pulse">
          <div className="h-12 bg-gray-300 rounded w-32"></div>
        </div>
      ) : (
        <select
          value={selectedConfiguration?.profile_name || ''}
          onChange={handleSelectChange}
          className="px-3 h-full w-full border border-gray-300 text-gray-700 focus:outline-none rounded focus:border-purple-300"
        >
          {configurations.map(({ id, profile_name }) => (
            <option key={id} value={profile_name}>
              {profile_name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default ProfileOptions;
