import React, { useState } from 'react';
import { Supabase } from '../variables/Supabase';

const Login = () => {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [notification, setNotification] = useState({ type: '', message: '', visible: false });

  const showNotification = (type: string, message: string) => {
    setNotification({ type, message, visible: true });
    setTimeout(() => {
      setNotification({ type: '', message: '', visible: false });
    }, 10000);
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const { error } = await Supabase.auth.signInWithOtp({
        email: email,
        options: {
            emailRedirectTo: `${window.location.origin}/app`,
        },
    });

    if (error) {
        showNotification('error', error.message);
    } else {
      showNotification('success', 'Check your email for the login link!');
    }
    setLoading(false);
  };

    return (
        <div className="h-screen w-screen flex flex-col justify-center items-center bg-gray-100">
            <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
                <h2 className="mb-6 text-2xl font-semibold text-center text-gray-700">Login</h2>
                <form onSubmit={handleLogin}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-600 font-medium mb-1">
                            Email address
                        </label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email address"
                            className="w-full p-2 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className={`w-full bg-purple-600 text-white py-3 px-4 rounded-md font-medium hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 transition duration-200 ease-in-out ${loading ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        disabled={loading}
                    >
                        {loading ? 'Loading...' : 'Login with Magic Link'}
                    </button>
                </form>
            </div>
            {notification.message && (
                    <div
                        className={`mt-4 p-4 rounded-md text-white font-medium ${notification.type === 'error' ? 'bg-red-500' : 'bg-green-500'
                            }`}
                    >
                        {notification.message}
                    </div>
                )}
        </div>
    );
};

export default Login;