// src/components/AdminConsole.js

import React, { useState, useEffect } from 'react';
import AdminConsoleDefaultProfile from './AdminConsoleDefaultProfile';
import AdminConsoleNewProfile from './AdminConsoleNewProfile';
import { fetchConfiguration, deleteConfiguration, postConfiguration } from './AdminConsoleDataQueries';
import { TrashIcon, CheckIcon } from '@heroicons/react/24/solid';
import { useAuth } from './Auth';
import { Switch } from '@headlessui/react';

interface Profile {
  id: number;
  profile_json: {
    url: string;
    backend: string;
    pipeline: string;
    converter: string;
    target: string;
    profile_name: string;
  };
  profile_default: boolean;
}

const AdminConfiguration = () => {
  const [url, setUrl] = useState('');
  const [backend, setBackend] = useState('');
  const [pipeline, setPipeline] = useState('');
  const [converter, setConverter] = useState('');
  const [target, setTarget] = useState('');
  const [id, setId] = useState<number | null>(null);
  const [message, setMessage] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [profileName, setProfileName] = useState('');
  const [tempProfileName, setTempProfileName] = useState('');
  const [duplicateNameError, setDuplicateNameError] = useState(false);
  const [initialValues, setInitialValues] = useState({ url: '', backend: '', pipeline: '', profile: '', converter: '', target: '' });
  const [isNewProfile, setIsNewProfile] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);
  const { userId } = useAuth();

  const converterOptions = {
    'PySigma': 'pysigma',
    'Sigmac': 'sigmac',
  };

  const targetOptions = {
    'None': 'none',
    'Microsoft Azure Log Analytics': 'ala',
    'Microsoft Defender': 'mdatp',
  };

  const backendOptions = {
    'None': 'none',
    'Splunk Enterprise': 'SplunkBackend',
    'Microsoft Defender': 'Microsoft365DefenderBackend',
  };

  const pipelineOptions = {
    'None': 'none',
    'Windows': 'windows_logsource_pipeline',
    'Sysmon': 'sysmon_pipeline',
    'Microsoft Defender': 'microsoft_365_defender_pipeline',
    'Crowdstrike': 'crowdstrike_fdr_pipeline',
  };

  const selectProfile = (profile: Profile) => {
    setProfileName(profile.profile_json.profile_name);
    setTempProfileName(profile.profile_json.profile_name);
    setId(profile.id);
    setUrl(profile.profile_json.url);
    setBackend(profile.profile_json.backend);
    setPipeline(profile.profile_json.pipeline);
    setConverter(profile.profile_json.converter);
    setTarget(profile.profile_json.target);

    setInitialValues({
      url: profile.profile_json.url,
      converter: profile.profile_json.converter,
      backend: profile.profile_json.backend,
      pipeline: profile.profile_json.pipeline,
      profile: profile.profile_json.profile_name,
      target: profile.profile_json.target,
    });
  };

  const hasFormChanged = () => {
    return initialValues.url !== url ||
      initialValues.backend !== backend ||
      initialValues.pipeline !== pipeline ||
      initialValues.converter !== converter ||
      initialValues.target !== target ||
      initialValues.profile !== tempProfileName ||
      duplicateNameError;
  };

  const handleTempProfileNameChange = (name: string) => {
    setTempProfileName(name);
    const existingProfile = profiles.find(
      (profile) => profile.profile_json.profile_name.toLowerCase() === name.toLowerCase()
    );
    if (existingProfile) {
      setDuplicateNameError(true);
    } else {
      setDuplicateNameError(false);
    }
  };

  const handleDeleteProfile = async () => {
    setShowConfirmation(false);
    if (profileName && id !== null && userId) {
      const { error } = await deleteConfiguration(userId, id);
      if (error) {
        setMessage(`Error: ${error.message}`);
      } else {
        setMessage('Configuration successfully deleted');
        fetchCurrentConfiguration();
      }
    }
  };

  const fetchCurrentConfiguration = async () => {
    const { data, error } = await fetchConfiguration();
    setLoading(false);

    if (error) {
      setMessage(`Error: ${error.message}`);
    } else if (data) {
      setProfiles(data);

      const defaultProfile = data.find(profile => profile.profile_default);
      if (defaultProfile) {
        selectProfile(defaultProfile);
      }
    }
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setProfileName(tempProfileName);

    if (userId && id !== null) {
      const { error } = await postConfiguration(userId, id, tempProfileName, url, backend, pipeline, isNewProfile, converter, target);
      if (error) {
        setMessage(`Error: ${error.message}`);
      } else {
        setIsNewProfile(false);
        setMessage('Configuration successfully saved');
        fetchCurrentConfiguration();
      }
    }
  };

  useEffect(() => {
    fetchCurrentConfiguration();
  }, []);

  return (
    <div className="container mx-auto mt-10 p-6 bg-white rounded shadow-md">
      <h1 className="text-3xl font-bold mb-5">Backend Configuration</h1>

      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-4 overflow-auto items-center">
          {loading
            ? Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="animate-pulse bg-gray-300 h-10 rounded w-20"
              />
            ))
            : profiles.map((profile) => (
              <button
                key={profile.id}
                onClick={() => selectProfile(profile)}
                className={`px-4 py-2 rounded focus:outline-none ${profile.profile_json.profile_name === profileName ? 'bg-purple-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`}
              >
                {profile.profile_json.profile_name}
              </button>
            ))}
          <div className="border-r-2 border-gray-400 h-6 mx-4"></div>
          <AdminConsoleNewProfile
            backendOptions={backendOptions}
            pipelineOptions={pipelineOptions}
            converterOptions={converterOptions}
            targetOptions={targetOptions}
            setProfileName={setProfileName}
            setTempProfileName={setTempProfileName}
            setConverter={setConverter}
            setTarget={setTarget}
            setUrl={setUrl}
            setBackend={setBackend}
            setPipeline={setPipeline}
            setMessage={setMessage}
            setIsNewProfile={setIsNewProfile}
            setInitialValues={setInitialValues}
          />
        </div>
        <AdminConsoleDefaultProfile />
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <hr className="my-6 border-t-2 border-gray-200" />

        {loading ? (
          <>
            <div className="animate-pulse">
              <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
              <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
              <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
              <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
            </div>
          </>
        ) : (
          <>
            <div>
              <label htmlFor="profile_name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                id="profile_name"
                type="text"
                value={tempProfileName}
                onChange={(e) => handleTempProfileNameChange(e.target.value)}
                className={`mt-1 w-full p-2 border ${duplicateNameError ? 'border-red-600' : 'border-gray-300'
                  } rounded text-gray-700 focus:outline-none focus:border-purple-300`}
                required
              />
              {duplicateNameError && (
                <div className="text-red-600">Profile name already exists</div>
              )}
            </div>

            <div className="space-y-4">
              <label htmlFor="converter" className="block text-sm font-medium text-gray-700">
                Converter
              </label>
              <Switch.Group as="div" className="flex items-center space-x-4">
                <Switch.Label passive className="text-sm text-gray-700">
                  PySigma
                </Switch.Label>
                <Switch
                  id="converter"
                  checked={converter === 'pysigma'}
                  onChange={isChecked => setConverter(isChecked ? 'pysigma' : 'sigmac')}
                  className={`${converter === 'pysigma' ? 'bg-purple-600' : 'bg-purple-600'
                    } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
                >
                  <span className="sr-only">Use Sigmac</span>
                  <span
                    className={`${converter === 'sigmac' ? 'translate-x-6' : 'translate-x-1'
                      } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                  />
                </Switch>
                <Switch.Label passive className="text-sm text-gray-700">
                  Sigmac
                </Switch.Label>
              </Switch.Group>
            </div>

            <div>
              <label htmlFor="backend" className="block text-sm font-medium text-gray-700">
                Backend
              </label>
              <select
                id="backend"
                value={backend}
                onChange={(e) => setBackend(e.target.value)}
                className="mt-1 w-full p-2 border border-gray-300 rounded text-gray-700 focus:outline-none focus:border-purple-300"
              >
                {Object.keys(backendOptions).map((key) => (
                  <option key={key} value={backendOptions[key as keyof typeof backendOptions]}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="pipeline" className="block text-sm font-medium text-gray-700">
                Pipeline
              </label>
              <select
                id="pipeline"
                value={pipeline}
                onChange={(e) => setPipeline(e.target.value)}
                className="mt-1 w-full p-2 border border-gray-300 rounded text-gray-700 focus:outline-none focus:border-purple-300"
              >
                {Object.keys(pipelineOptions).map((key) => (
                  <option key={key} value={pipelineOptions[key as keyof typeof pipelineOptions]}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="target" className="block text-sm font-medium text-gray-700">
                Target
              </label>
              <select
                id="target"
                value={target}
                onChange={(e) => setTarget(e.target.value)}
                className="mt-1 w-full p-2 border border-gray-300 rounded text-gray-700 focus:outline-none focus:border-purple-300"
              >
                {Object.keys(targetOptions).map((key) => (
                  <option key={key} value={targetOptions[key as keyof typeof targetOptions]}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                URL
              </label>
              <input
                id="url"
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="mt-1 w-full p-2 border border-gray-300 rounded text-gray-700 focus:outline-none focus:border-purple-300"
                pattern="^((https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+|localhost)(:\d+)?)(\/.*)?$"
                title="Please enter a valid URL with http or https (optional), a domain name or localhost, and an optional port"
                required
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              />
              {showTooltip && (
                <div className="bg-gray-100 text-gray-700 p-2 rounded mt-1">
                  <p>Please enter a valid URL with http or https, a domain name or localhost, and an optional port.</p>
                </div>
              )}
            </div>

            <div className="flex justify-end space-between">
              <button
                type="button"
                onClick={() => setShowConfirmation(true)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none mr-4 flex justify-center items-center"
              >
                <TrashIcon className="h-5 w-5 mr-1" />
                Delete
              </button>

              <button
                type="submit"
                className={`bg-purple-500 ${(!hasFormChanged() || duplicateNameError) && 'opacity-50 cursor-not-allowed'} hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none flex justify-center items-center`}
                disabled={!hasFormChanged() || duplicateNameError}
              >
                <CheckIcon className="h-5 w-5 mr-1" />
                Save
              </button>
            </div>
          </>
        )}
      </form>

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md">
            <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
            <p className="mb-4">Are you sure you want to delete the configuration for {profileName}?</p>
            <div className="flex justify-center">
              <button
                type="button"
                onClick={() => setShowConfirmation(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none mr-2"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleDeleteProfile}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {message && (
        <div className="mt-4 text-lg text-green-600">
          <p>{message}</p>
        </div>
      )}
    </div>
  );
};

export default AdminConfiguration;