import React from 'react';
import { PlusIcon } from '@heroicons/react/24/solid';

interface AdminConsoleNewProfileProps {
  targetOptions: { [key: string]: string },
  converterOptions: { [key: string]: string },
  backendOptions: { [key: string]: string },
  pipelineOptions: { [key: string]: string },
  setProfileName: (value: string) => void,
  setTempProfileName: (value: string) => void,
  setTarget: (value: string) => void,
  setConverter: (value: string) => void,
  setUrl: (value: string) => void,
  setBackend: (value: string) => void,
  setPipeline: (value: string) => void,
  setMessage: (value: string) => void,
  setIsNewProfile: (value: boolean) => void,
  setInitialValues: (value: {
    url: string;
    backend: string;
    pipeline: string;
    profile: string;
    converter: string;
    target: string;
  }) => void;  
}

const AdminConsoleNewProfile: React.FC<AdminConsoleNewProfileProps> = ({
  targetOptions, converterOptions, backendOptions, pipelineOptions, 
  setProfileName, setTempProfileName, setTarget, setConverter, setUrl, 
  setBackend, setPipeline, setMessage, setIsNewProfile, setInitialValues
}) => {

  const resetProfile = () => {
    const defaultBackend = Object.values(backendOptions)[0];
    const defaultPipeline = Object.values(pipelineOptions)[0];
    const defaultCoverter = Object.values(converterOptions)[0];
    const defaultTarget = Object.values(targetOptions)[0];

    setProfileName('');
    setTempProfileName('');
    setUrl('');
    setBackend(defaultBackend);
    setPipeline(defaultPipeline);
    setMessage('');
    setIsNewProfile(true);
    setConverter(defaultCoverter);
    setTarget(defaultTarget)
    
    return { url: '', backend: defaultBackend, pipeline: defaultPipeline, profile: '', converter: defaultCoverter, target: defaultTarget }
  }

  const handleNewProfile = () => {
    setInitialValues(resetProfile());
  };

  return (
    <button
      type="button"
      onClick={handleNewProfile}
      className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none flex justify-center items-center"
    >
      <PlusIcon className="h-5 w-5 mr-1" />
      New
    </button>
  );
};

export default AdminConsoleNewProfile;
